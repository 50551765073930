import React, { createContext, useState, useContext } from "react"

const SidemenuContext = createContext()

export const useSidemenu = () => useContext(SidemenuContext)

export const SidemenuProvider = ({ children }) => {
  const [isApplyDocsMenuOpen, setApplyDocsIsMenuOpen] = useState(false)
  const [isRequestMeetingMenuOpen, setRequestMeetingMenuOpen] = useState(false)
  const [isSendMessageMenuOpen, setSendMessageMenuOpen] = useState(false)

  const toggleApplyDocsMenu = () => {
    setApplyDocsIsMenuOpen(!isApplyDocsMenuOpen)
  }

  const toggleRequestMeetingMenu = () => {
    setRequestMeetingMenuOpen(!isRequestMeetingMenuOpen)
  }

  const toggleSendMessageMenu = () => {
    setSendMessageMenuOpen(!isSendMessageMenuOpen)
  }

  return (
    <SidemenuContext.Provider
      value={{
        isApplyDocsMenuOpen,
        toggleApplyDocsMenu,
        isRequestMeetingMenuOpen,
        toggleRequestMeetingMenu,
        isSendMessageMenuOpen,
        toggleSendMessageMenu,
      }}
    >
      {children}
    </SidemenuContext.Provider>
  )
}
