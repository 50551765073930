// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-advantages-uk-js": () => import("./../../../src/pages/all-advantages-uk.js" /* webpackChunkName: "component---src-pages-all-advantages-uk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-sections-login-signup-side-navs-password-confirm-js": () => import("./../../../src/sections/login_signup_side_navs/PasswordConfirm.js" /* webpackChunkName: "component---src-sections-login-signup-side-navs-password-confirm-js" */),
  "component---src-templates-admin-info-admin-info-about-js": () => import("./../../../src/templates/admin_info/AdminInfoAbout.js" /* webpackChunkName: "component---src-templates-admin-info-admin-info-about-js" */),
  "component---src-templates-admin-profile-admin-profile-dashboard-requests-js": () => import("./../../../src/templates/admin_profile/AdminProfileDashboardRequests.js" /* webpackChunkName: "component---src-templates-admin-profile-admin-profile-dashboard-requests-js" */),
  "component---src-templates-admin-settings-admin-settings-js": () => import("./../../../src/templates/admin_settings/AdminSettings.js" /* webpackChunkName: "component---src-templates-admin-settings-admin-settings-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-info-advantages-page-js": () => import("./../../../src/templates/info/AdvantagesPage.js" /* webpackChunkName: "component---src-templates-info-advantages-page-js" */),
  "component---src-templates-info-article-groups-page-js": () => import("./../../../src/templates/info/ArticleGroupsPage.js" /* webpackChunkName: "component---src-templates-info-article-groups-page-js" */),
  "component---src-templates-info-events-list-page-js": () => import("./../../../src/templates/info/EventsListPage.js" /* webpackChunkName: "component---src-templates-info-events-list-page-js" */),
  "component---src-templates-info-search-results-map-page-js": () => import("./../../../src/templates/info/SearchResultsMapPage.js" /* webpackChunkName: "component---src-templates-info-search-results-map-page-js" */),
  "component---src-templates-school-school-page-about-js": () => import("./../../../src/templates/school/SchoolPageAbout.js" /* webpackChunkName: "component---src-templates-school-school-page-about-js" */),
  "component---src-templates-school-school-page-contacts-js": () => import("./../../../src/templates/school/SchoolPageContacts.js" /* webpackChunkName: "component---src-templates-school-school-page-contacts-js" */),
  "component---src-templates-school-school-page-fees-js": () => import("./../../../src/templates/school/SchoolPageFees.js" /* webpackChunkName: "component---src-templates-school-school-page-fees-js" */),
  "component---src-templates-school-school-page-gallery-js": () => import("./../../../src/templates/school/SchoolPageGallery.js" /* webpackChunkName: "component---src-templates-school-school-page-gallery-js" */),
  "component---src-templates-school-school-page-js": () => import("./../../../src/templates/school/SchoolPage.js" /* webpackChunkName: "component---src-templates-school-school-page-js" */),
  "component---src-templates-school-school-page-scholarships-js": () => import("./../../../src/templates/school/SchoolPageScholarships.js" /* webpackChunkName: "component---src-templates-school-school-page-scholarships-js" */),
  "component---src-templates-school-school-page-sports-js": () => import("./../../../src/templates/school/SchoolPageSports.js" /* webpackChunkName: "component---src-templates-school-school-page-sports-js" */),
  "component---src-templates-school-school-page-subjects-js": () => import("./../../../src/templates/school/SchoolPageSubjects.js" /* webpackChunkName: "component---src-templates-school-school-page-subjects-js" */),
  "component---src-templates-school-school-page-summer-centers-js": () => import("./../../../src/templates/school/SchoolPageSummerCenters.js" /* webpackChunkName: "component---src-templates-school-school-page-summer-centers-js" */),
  "component---src-templates-user-profile-user-compare-schools-map-page-js": () => import("./../../../src/templates/user_profile/UserCompareSchoolsMapPage.js" /* webpackChunkName: "component---src-templates-user-profile-user-compare-schools-map-page-js" */),
  "component---src-templates-user-profile-user-compare-schools-page-js": () => import("./../../../src/templates/user_profile/UserCompareSchoolsPage.js" /* webpackChunkName: "component---src-templates-user-profile-user-compare-schools-page-js" */),
  "component---src-templates-user-profile-user-profile-info-page-js": () => import("./../../../src/templates/user_profile/UserProfileInfoPage.js" /* webpackChunkName: "component---src-templates-user-profile-user-profile-info-page-js" */),
  "component---src-templates-user-profile-user-profile-saved-page-js": () => import("./../../../src/templates/user_profile/UserProfileSavedPage.js" /* webpackChunkName: "component---src-templates-user-profile-user-profile-saved-page-js" */)
}

