import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import { closeNav } from "../scripts/sideNavMenu.js"

import XButtonIcon from "../svg/x-button.inline.svg"
import { useTranslation } from "react-i18next"
import { openNavUser } from "../scripts/loginMenus"
import { useCurrentUser } from "../components/CurrentUserContext"

const SideNavMenuSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const [selectId, setSelectId] = useState()
  const { currentUser } = useCurrentUser()

  const [pathname, setPathname] = useState("")
  useEffect(() => {
    setPathname(window.location.pathname)
    const anchor = window.location.href.split('#')[1];
    scrollSelect(anchor || "first-entry-block")
  }, [])

  const openNavSection = id => {
    if (!currentUser) {
      openNavUser()
      closeNav()
    } else {
      setSelectId(id)
      navigate(`/${lang}/profile/compare-schools`)
    }
  }
  const scrollSelect = id => {
    setSelectId(id)
    if (pathname.length > 4) {
      navigate(`/${lang}/#${id}`)
    } else {
      const violation = document.getElementById(id)
      window.scrollTo({
        top: violation?.offsetTop,
        behavior: "smooth",
      })
      closeNav()
    }
  }

  return (
    <div id="side-nav-menu" className="sidenav container px-7">
      {/* LOGO */}
      <div className="row">
        <div className="sidenav-top col-12">
          <div className="logo_full mR-20">
            <div className="logo_full_block logo_full_block_1">
              {t("main.sideNav.smart")}
            </div>
            <div className="logo_full_block logo_full_block_2">
              {t("main.sideNav.admissions")}
            </div>
            <div className="logo_full_block logo_full_block_3">
              {t("main.sideNav.service")}
            </div>
            <div className="logo_shrinked_block">
              <pre>S A S</pre>
            </div>
          </div>
          <div className="closebtn icon_button" onClick={closeNav}>
            <XButtonIcon className="x-button" />
            <div className="menu_title right closebtn">
              {t("main.sideNav.close")}
            </div>
          </div>
        </div>
      </div>
      {/* OPTIONS */}
      <div className="sidenav-items-list row">
        <div
          className="sidenav-item col-12 d-flex justify-content-start py-3 align-items-center"
          id="i1"
          onClick={() => scrollSelect("schoolsDirectory")}
        >
          <div
            className={`${
              selectId === "schoolsDirectory" && "item1-red-square"
            }`}
          ></div>
          {t("main.sideNav.schoolsDirectory")}
        </div>
        <div
          className="sidenav-item col-12 d-flex justify-content-start py-3 align-items-center"
          id="i2"
          onClick={() => scrollSelect("handbooks")}
        >
          <div
            className={`${selectId === "handbooks" && "item1-red-square"}`}
          ></div>

          {t("main.sideNav.handbooks")}
        </div>
        <div
          className="sidenav-item col-12 d-flex justify-content-start py-3 align-items-center"
          id="i3"
          onClick={() => scrollSelect("upcomingEvent")}
        >
          <div
            className={`${selectId === "upcomingEvent" && "item1-red-square"}`}
          ></div>
          {t("main.sideNav.upcomingEvents")}
        </div>
        <div
          className="sidenav-item col-12 d-flex justify-content-start py-3 align-items-center"
          id="i4"
          onClick={() => openNavSection("compareSchools")}
        >
          <div
            className={`${
              selectId === "compareSchools" && "item1-red-square"
            }`}
          ></div>
          {t("main.sideNav.compareSchools")}
        </div>
        <div
          className="sidenav-item col-12 d-flex justify-content-start py-3 align-items-center"
          id="i5"
          onClick={() => scrollSelect("glossary")}
        >
          <div
            className={`${selectId === "glossary" && "item1-red-square"}`}
          ></div>
          {t("main.sideNav.glossary")}
        </div>
        <div
          className="sidenav-item col-12 d-flex justify-content-start py-3 align-items-center"
          id="i7"
          onClick={() => scrollSelect("aboutUs")}
        >
          <div
            className={`${selectId === "aboutUs" && "item1-red-square"}`}
          ></div>
          {t("main.sideNav.aboutUs")}
        </div>
      </div>
    </div>
  )
}

export default SideNavMenuSection
