//User login
export function openNavUser() {
  document.getElementById("side-nav-user").style.display = "block"
  document.body.style.backgroundColor = "rgba(0, 0, 0, 0.5)"
}

export function closeNavUser() {
  document.getElementById("side-nav-user").style.display = "none"
  document.body.style.backgroundColor = ""
}

//School login
export function openNavSchool() {
  document.getElementById("side-nav-school").style.display = "block"
  document.body.style.backgroundColor = "rgba(0, 0, 0, 0.5)"
}

export function closeNavSchool() {
  document.getElementById("side-nav-school").style.display = "none"
  document.body.style.backgroundColor = ""
}

//TOGLE EYE PASSWORD
export function changeEye(eye) {
  const password = document.querySelector("#password")
  const type =
    password.getAttribute("type") === "password" ? "text" : "password"
  password.setAttribute("type", type)

  eye.classList.toggle("toggled")
}

export function changeEye2(eye) {
  const password = document.querySelector("#password2")
  const type =
    password.getAttribute("type") === "password" ? "text" : "password"
  password.setAttribute("type", type)

  eye.classList.toggle("toggled")
}
