// i18n.js
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { languages, defaultLanguage } from "../languages"

// Load translation resources
const resources = {
  en: {
    translation: require("../locales/en/translation.json"),
  },
  uk: {
    translation: require("../locales/uk/translation.json"),
  },
  ru: {
    translation: require("../locales/ru/translation.json"),
  },
}

// Check if localStorage is defined before accessing it
const storedLangKey =
  typeof localStorage !== "undefined"
    ? localStorage.getItem("gatsby-intl-language")
    : null
const fallbackLanguage =
  storedLangKey && languages.includes(storedLangKey)
    ? storedLangKey
    : defaultLanguage

// console.log("Fallback", fallbackLanguage)

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: fallbackLanguage,
    lng: fallbackLanguage, // Set the initial language to the stored language preference
    interpolation: {
      escapeValue: false, // React already handles XSS
    },
  })

i18n.languages = languages.slice() // Set available languages

export default i18n
