/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"

import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/scrollbar/scrollbar.scss"

import "../styles/bootstrap.scss"

import "./layout.css"
import "../styles/main.css"
import "../styles/custom.css"
import "../styles/font.css"
import "../styles/global.css"
import "../styles/dropdown.css"
import "../styles/sideNavMenu.css"
import "../styles/sideNavGlossary.css"
import "../styles/sideNavUser.css"
import "../styles/sideNavSchool.css"
import "../styles/headerShrink.css"
import "../styles/slider.css"

import "../styles/infoPage.css"
import "../styles/school_item.css"
import "../styles/school_item_headerShrink.css"

import "../styles/userProfile.css"
import "../styles/floatInput.css"
import "../styles/userSavedSchools.css"
import "../styles/userCompareSchools.css"
import "../styles/search_results.css"
import "../styles/dropdownViaSelect.css"
import "../styles/sideNavMoreFilters-school-type.css"

import "../styles/school-item-gallery-slider.css"

import "../styles/schoolAdminDashboard.css"
import "../styles/schoolAdminSchoolInfo.css"

import "../styles/sideNavSchoolItem.css"
import "../styles/school_item-about.css"

import MetaTags from "react-meta-tags"
import { withTrans } from "../i18n/withTrans"
import { useTranslation } from "react-i18next"
import { CurrentUserProvider } from "./CurrentUserContext"
import SideNavMenuSection from "../sections/SideNavMenuSection"
import { SidemenuProvider } from "./SidemenuContext"

const Layout = ({
  children,
  wrapperClass,
  pageTitle,
  keywordsContent,
  descriptionContent,
  isSecondLayout,
}) => {
  const { i18n } = useTranslation()
  const currLang = i18n.language
  const lng =
    typeof window !== "undefined"
      ? window.location.pathname.split("/")[1]
      : null

  if (lng && currLang !== lng) {
    i18n.changeLanguage(lng)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MetaTags>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=786" />
        <meta name="keywords" content={keywordsContent} />
        <meta name="description" content={descriptionContent} />
        <title>{pageTitle}</title>
      </MetaTags>
      {!isSecondLayout ? (
        <CurrentUserProvider>
          <SidemenuProvider>
            <div className={`global-wrapper ${wrapperClass || ""}`}>
              {children}
              <SideNavMenuSection />
            </div>
          </SidemenuProvider>
        </CurrentUserProvider>
      ) : (
        <div className={`global-wrapper ${wrapperClass || ""}`}>
          {children}
          <SideNavMenuSection />
        </div>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
