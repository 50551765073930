/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/scss/index.scss"

export const onClientEntry = () => {
  const languagePrefixes = ["/en", "/uk", "/ru"]

  const userLang = navigator.language || navigator.userLanguage
  const langKey = userLang.startsWith("ru")
    ? "uk"
    : userLang.startsWith("uk")
    ? "uk"
    : "en"
  localStorage.setItem("gatsby-intl-language", langKey)

  const userPickedLanguage = localStorage.getItem("user-picked-language")
  const finalLangKey = userPickedLanguage ? userPickedLanguage : langKey

  const path = window.location.pathname

  const doesNotStartWithAnyPrefix = languagePrefixes.every(
    prefix => !path.startsWith(prefix)
  )

  if (doesNotStartWithAnyPrefix) {
    window.location.pathname = `/${finalLangKey}${window.location.pathname}`
  }
}
