import React, { useEffect, useState } from "react"
import axios from "../http/axios"
import { AUTH_TOKEN_KEY } from "../constants/constants"
import { globalHistory } from "@reach/router"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"

export const CurrentUserContext = React.createContext(null)

function useMergeState(initialState) {
  const [state, setState] = useState(initialState)

  const setMergedState = newState => {
    if (!newState) {
      setState(newState)
    } else {
      setState(prevState => Object.assign({}, prevState, newState))
    }
  }
  return [state, setMergedState]
}

export const CurrentUserProvider = ({ isSecondLayout, children }) => {
  const [currentUser, setCurrentUser] = useMergeState(null)
  const [userLoading, setLoading] = useState(false)
  const { i18n } = useTranslation()

  const fetchCurrentUser = async isUpdate => {
    if ((!userLoading && isAuthenticated() && !currentUser) || isUpdate) {
      try {
        setLoading(true)
        let response = await axios.get(
          `/accounts/current?lang=${i18n.language}`
        )
        const user = await response.data
        setCurrentUser(user)
        setLoading(false)
      } catch (e) {}
    }
  }

  const setUser = user => {
    setCurrentUser(user)
  }

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY)
    setUser(null)
    if (isBrowser()) {
      if (window.location.pathname.includes("profile")) {
        navigate("/")
      }
    }
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") fetchCurrentUser()
    })
  }, [])

  useEffect(() => {
    fetchCurrentUser()
  }, [])

  return (
    <CurrentUserContext.Provider
      value={{ currentUser, fetchCurrentUser, userLoading, setUser, logout }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => React.useContext(CurrentUserContext)

export const isAuthenticated = () => {
  if (isBrowser()) {
    return !!localStorage.getItem(AUTH_TOKEN_KEY)
  }
  return false
}

export const isBrowser = () => typeof window !== "undefined"
